<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import VueEasyLightbox from "vue-easy-lightbox";
import { apiRequest } from "@/helpers/api-call";
import { format } from "date-fns";

export default {
  page: {
    title: "Détails trajets effectués",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, VueEasyLightbox },
  data() {
    return {
      title: "Détails trajets effectués",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "details",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25],
      filter: null,
      filterOn: [],
      date: "",
      dateDepart: "",
      dateArrive: "",
      tableData: [],
      passagerId: [],
      dataUser: [],
      details: "",
      sortBy: "orderid",
      sortDesc: false,
      conducteur: [
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "date", sortable: true, label: "Départ" },
        { key: "prix", sortable: true, label: "Prix" },
        { key: "billingname", sortable: true, label: "Passagers" },
        { key: "statut", sortable: true, label: "Statut" },
      ],
      fields: [
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "date", sortable: true, label: "Départ" },
        { key: "prix", sortable: true, label: "Prix" },
        { key: "passager", sortable: true, label: "Passagers" },
        { key: "statut", sortable: true, label: "Statut" },
      ],
      passager: [
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "date", sortable: true, label: "Départ" },
        { key: "prix", sortable: true, label: "Prix" },
        { key: "billingname", sortable: true, label: "Passagers" },
        { key: "statut", sortable: true, label: "Statut" },
      ],
      username: "",
      dataUserTable: [],
      avatar: "",
      tokenUser: JSON.parse(localStorage.getItem("user")).tokenSalt,
      idtrajet: "",
      fisrtname: "",
      lastname: "",
      genre: "",
      boite_postale: "",
      profession: "",
      birthday: "",
      adress: "",
      city: "",
      country: "",
      number_phone: "",
      email: "",
      password: "",
      showPassword: false,
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      index: 0,
      allImage: {
        avatar: "",
        permis: "",
        vehicule: "",
        cni: "",
      },
      img: "",
      vue: false,
      visible: false,
      showModal: false,
      HistoriqueConducteur: false,
      HistoriquePassager: false,
      picture: ["PROFIL", "CNI", "PERMIS", "VOITURE"],
      imgs: "",
      startLocation: {
        lat: 0,
        lng: 0,
      },
      coordinates: [],
      infoPosition: null,
      infoContent: null,
      infoOpened: false,
      infoCurrentKey: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      confirmation: null,
      idReceiver: [],
      confirmation_selected: null,
      showMessage: false,
    };
  },
  async mounted() {
    this.idtrajet = this.$route.params.idtrajet;
    //Chargement des données
    //console.log("Détails", this.idtrajet);
    //console.log("Table", this.tableData);

    const data = await apiRequest("GET", "trajet/?id=" + this.idtrajet, false);
    if (data && data.data) {
     

      this.tableData = data.data;
      this.date = new Date(this.tableData.dateHeureDepart).toLocaleString(
        "fr-FR"
      );
      this.dateDepart = format(
        new Date(this.tableData.dateHeureDepart),
        "hh:mm:ss"
      );
      this.dateArrive = format(
        new Date(this.tableData.dateHeureArrive),
        "hh:mm:ss"
      );

      if (data.data.conducteur.userAvatarCode !== null) {
        this.allImage.avatar =
          `https://rmobility.ovh/admin/image/?codeImage=` +
          data.data.conducteur.userAvatarCode +
          `&token=` +
          this.tokenUser;
      } else {
        this.allImage.avatar = require("@/assets/images/users/attente.png");
      }

      if (data.data.conducteur.vehicule.autoMotoPictureCode !== null) {
        this.allImage.permis =
          `https://rmobility.ovh/admin/image/?codeImage=` +
          data.data.conducteur.vehicule.autoMotoPictureCode +
          `&token=` +
          this.tokenUser;
      } else {
        this.allImage.permis = require("@/assets/images/users/attente.png");
      }
    }

    var lespassagers = data.data.passagers;
   

    this.passagerId = [];
    lespassagers.forEach((element) => {
      if (element.statut == "ACTIF") {
        this.passagerId.push(element.idClient);
      }
    });

    for (let i = 0; i < this.passagerId.length; i++) {
      const Users = await apiRequest(
        "POST",
        "admin/detail-user",
        { id: this.passagerId[i] },
        false
      );

      if (Users && Users.data) {
       
        this.dataUserTable.push(Users.data);
       
      }
    }
    this.dataMap();
    this.dataConfirmation();
  },
  methods: {
    async dataMap() {
      const data = await apiRequest(
        "GET",
        "admin/matching?idTrajet=" + this.idtrajet,
        false
      );
      if (data && data.data) {
        var all = new Array();
        data.data.map((element) => {
          if (element.data) {
            element.data.forEach((elementc) => {
              var coordinat = {
                name: element.user,
                lat: elementc.latitude,
                lng: elementc.longitude,
              };
              all.push(coordinat);
            });
            this.startLocation.lat = all[0].lat;
            this.startLocation.lng = all[0].lng;
          }
        });
       
        this.coordinates = all;
      }
    },
    async dataConfirmation() {
      const data = await apiRequest(
        "POST",
        "rappel/confirmation",
        { idTrajet: this.idtrajet },
        false
      );
      if (data && data.data) {
        this.confirmation = data.data;

        this.confirmation.forEach((element) => {
          if (element.chats.length > 1) {
            this.idReceiver.push(element.info.receiver);
          }
        });
       
      }
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
   
    ValidationStatus: function(validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    Submit: function() {
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      alert("data submit");
    },
    showImg(index) {
      this.index = index;
      this.visible = true;
    },
    showImage(img) {
      //console.log(img.srcElement.src)
      this.imgs = img.srcElement.src;
     
      this.show();
    },
    handleHide() {
      this.visible = false;
    },
    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    show() {
      this.visible = true;
    },
    getPosition: function(marker) {
      return {
        lat: parseFloat(marker.lat),
        lng: parseFloat(marker.lng),
      };
    },
    toggleInfo: function(marker, key) {

      this.infoPosition = this.getPosition(marker);
      this.infoContent = marker.name;
      if (this.infoCurrentKey == key) {
        this.infoOpened = !this.infoOpened;
      } else {
        this.infoOpened = true;
        this.infoCurrentKey = key;
      }
    },
    showConf(id) {
     
      this.confirmation.forEach((element) => {
        if (element.info.receiver == id) {
          this.confirmation_selected = element.chats;
        
          this.showMessage = true;
        }
      });
    },
    async resend(id) {
  

      const data = await apiRequest(
        "POST",
        "admin/trajets/resend-conf",
        {
          trajet: this.idtrajet,
          receiver: id,
        },
        false
      );
     
      if (data && data.data) {
       console.log(data.data);
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center"></div>
            <div class="row py-5">
              <div class="col-md-4">
                <div class="row">
                  <a
                    class="image-popup-vertical-fit d-flex justify-content-center"
                    @click="showImage"
                  >
                    <img
                      style="cursor: pointer;"
                      class="avatar-xl"
                      :src="`${this.allImage.avatar}`"
                      data-holder-rendered="true"
                    />
                  </a>
                  <vue-easy-lightbox
                    :visible="visible"
                    :imgs="imgs"
                    @hide="handleHide"
                  ></vue-easy-lightbox>
                  <div class="mt-1">
                    <i class="fas fa-user align-middle mr-2 ml-2"></i>
                    <span class="font-weight-bold"
                      >{{ this.tableData.conducteur.personne.firstname }}
                      {{ this.tableData.conducteur.personne.lastname }}</span
                    >
                    <div>
                      <i class="fas fa-comment mr-2 ml-2"></i
                      ><span>"{{ this.tableData.infosSup }}"</span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <a
                    class="image-popup-vertical-fit d-flex justify-content-center"
                    @click="showImage"
                  >
                    <img
                      style="cursor: pointer;"
                      class="avatar-xl mt-2"
                      :src="`${this.allImage.permis}`"
                      data-holder-rendered="true"
                    />
                    <!-- <img class="img-time-matters" :src="require(`../assets/time-comparison-${locale}.png`)"> -->
                  </a>
                  <div class="mt-1">
                    <div class="mt-3">
                      <i class="fas fa-fan mr-2 ml-2"></i>Climatisation:
                      <span>{{
                        this.tableData.conducteur.vehicule.aClimatisation ==
                        false
                          ? "Non"
                          : "Oui"
                      }}</span>
                    </div>
                    <div class="">
                      <i class="fas fa-fan ml-2 mr-1"></i> Immatriculation :
                      <span
                        >{{
                          this.tableData.conducteur.vehicule
                            .autoMotoImmatriculation
                        }}
                        min</span
                      >
                    </div>
                    <div class="">
                      <i class="fas fa-palette ml-2 mr-2"></i> Couleur :
                      <span>{{
                        this.tableData.conducteur.vehicule.autoMotoCouleur
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="mt-4" style="text-align: center;">
                  <button
                    v-if="idReceiver.includes(tableData.conducteur.id)"
                    title="Confirmation"
                    style="background: gray; color: white;"
                    class="btn mb-2 mr-2"
                    @click="showConf(tableData.conducteur.id)"
                  >
                    <i class="mdi mdi-wallet align-middle mr-2"></i>Confirmation
                  </button>
                  <button
                    v-else
                    title="Renvoyer"
                    style="background: maroon; color: white;"
                    class="btn mb-2 mr-2"
                    @click="resend(tableData.conducteur.id)"
                  >
                    <i class="fa fa-upload align-middle mr-2"></i>Renvoyer
                  </button>
                </div>
              </div>
              <span class="vertical-line"></span>
              <div class="" v-for="(item, index) in dataUserTable" :key="index">
                <div class="row">
                  <a class="image-popup-vertical-fit ml-5" @click="showImage">
                    <img
                      style="cursor: pointer; width:50px;"
                      class="avatar avatar-15 rounded-circle"
                      :src="
                        `https://rmobility.ovh/admin/image/?codeImage=${item.userAvatarCode}&token=${tokenUser}`
                      "
                      data-holder-rendered="true"
                    />
                  </a>
                  <div class="ml-2">
                    {{ item.personne.firstname + " " + item.personne.lastname }}

                    <span
                      class="fas fa-map-marker-alt text-primary ml-2 mr-1"
                    ></span
                    >{{ tableData.lieuDepart.nom }}

                    <span
                      class="fas fa-map-marker-alt text-danger ml-2 mr-1"
                    ></span
                    >{{ tableData.lieuArrivee.nom }}

                    <span class="badge badge-success"
                      >{{ tableData.prix }} XOF</span
                    >

                    <span class="ml-4" style="text-align: center;">
                      <button
                        v-if="idReceiver.includes(item.id)"
                        title="Confirmation"
                        style="background: gray; color: white;"
                        class="btn mb-2 mr-2"
                        @click="showConf(item.id)"
                      >
                        <i class="mdi mdi-wallet align-middle mr-2"></i
                        >Confirmation
                      </button>
                      <button
                        v-else
                        title="Renvoyer"
                        style="background: maroon; color: white;"
                        class="btn mb-2 mr-2"
                        @click="resend(item.id)"
                      >
                        <i class="fa fa-upload align-middle mr-2"></i>Renvoyer
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <GmapMap
              :center="startLocation"
              :zoom="10"
              map-type-id="terrain"
              style="width: 100%; height: 400px"
            >
              <gmap-info-window
                :options="infoOptions"
                :position="infoPosition"
                :opened="infoOpened"
                @closeclick="infoOpened = false"
              >
                {{ infoContent }}
              </gmap-info-window>
              <GmapMarker
                :key="index"
                v-for="(item, index) in coordinates"
                :position="getPosition(item)"
                :clickable="true"
                :draggable="true"
                @click="toggleInfo(item, index)"
              />
            </GmapMap>
          </div>
        </div>
      </div>
    </div>

    <!-- -->
    <b-modal
      id="modal-lg"
      size="lg"
      v-model="showMessage"
      title="Confirmation de fin"
      centered
    >
      <div
        class="ml-2"
        v-for="(item, index) in confirmation_selected"
        :key="index"
      >
        <div v-if="item.text" class="row" style="font-size: 16px;">
          <span style="font-size: 16px; color: green;">
            <i class="fas fa-comment mr-2" width="30" /> </span
          >{{ item.text }}
        </div>
      </div>

      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showMessage = false"
          >Fermer</b-button
        >
      </template>
    </b-modal>
    <!-- -->
  </Layout>
</template>

<style>
label {
  font-size: 20px;
}
.divider {
  width: 100%;
  border: solid 2px blue;
}
.blueG {
  border: solid 1px #8608b8ad;
}
.YelG {
  border: solid 1px #ff6545;
}
.text-blueG {
  color: #8608b8ad;
}
.vertical-line {
  border-left: 2px solid #000;
  height: 430px;
  margin: 0 20px;
}
</style>
